import { render, staticRenderFns } from "./PropostasConsulta.vue?vue&type=template&id=36b1b5d7&scoped=true"
import script from "./PropostasConsulta.vue?vue&type=script&lang=js"
export * from "./PropostasConsulta.vue?vue&type=script&lang=js"
import style0 from "./PropostasConsulta.vue?vue&type=style&index=0&id=36b1b5d7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b1b5d7",
  null
  
)

export default component.exports